/// @see https://git.obis-concept.de/obis-concept/tools/web-build-essentials/-/blob/9f107887b38f026f83ee7ff020999632a7cd3886/utility/styles/mixins/font-face.scss

/// Convert a given font style name to it's corresponding weight
///
/// @param {string} $style The name to convert
/// @returns the corresponding font-weight value
///
/// @example
///   font-weight: fontStyleNameToWeight("Regular"); // = 500
///   font-weight: fontStyleNameToWeight("BoldItalic") // = 700
@function fontStyleNameToWeight($style) {
  // if is already a proper number, return it directly with no conversion
  @if (type-of($style) == "number") {
    @return $style;
  }

  @if (str-index($style, "100")) {
    @return 100;
  }

  @if (str-index($style, "200")) {
    @return 200;
  }

  @if (str-index($style, "300")) {
    @return 300;
  }

  @if (str-index($style, "400")) {
    @return 400;
  }

  @if (str-index($style, "500")) {
    @return 500;
  }

  @if (str-index($style, "600")) {
    @return 600;
  }

  @if (str-index($style, "700")) {
    @return 700;
  }

  @if (str-index($style, "800")) {
    @return 800;
  }

  @if (str-index($style, "900")) {
    @return 900;
  }

  /// see https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
  @if (str-index($style, "Thin")) {
    @return 100;
  }

  @if (str-index($style, "ExtraLight")) or (str-index($style, "UltraLight")) {
    @return 200;
  }

  @if (str-index($style, "Light")) {
    @return 300;
  }

  @if (str-index($style, "Normal")) or (str-index($style, "Regular")) {
    @return 400;
  }

  @if (str-index($style, "Medium")) {
    @return 500;
  }

  @if (str-index($style, "SemiBold")) or (str-index($style, "DemiBold")) {
    @return 600;
  }

  @if (str-index($style, "ExtraBold")) or (str-index($style, "UltraBold")) {
    @return 800;
  }

  // Check for "Bold" at the end to prevent false positives
  @if (str-index($style, "Bold")) {
    @return 700;
  }

  @if (str-index($style, "Black")) or (str-index($style, "Heavy")) {
    @return 900;
  }

  // Return "Regular" by default as these are mostly lacking their name in Italic variants ("Italic" instead of "RegularItalic")
  @return 400;
}

/// Convert a given font style name to it's corresponding font style
///
/// @param {string} $style The name to convert
/// @returns the corresponding font-style value
///
/// @example
///   font-weight: fontStyleNameToWeight("Regular"); // = normal
///   font-weight: fontStyleNameToWeight("BoldItalic") // = italic
@function fontStyleNameToStyle($style) {
  @if (str-index($style, "Italic")) {
    @return italic;
  } @else {
    @return normal;
  }
}

/// Include a font by it's name from a Neos package
///
/// @param {string} $name The family name of the font
/// @param {string} $style The style of the font (e.g. "Regular" or "BoldItalic")
/// @param {string} $fontDisplay The font-display to use ("fallback" by default)
/// @param {string} $packageKey The package key to load the font from ("Obis.Site" by default)
/// @param {string} $prefix The path prefix to use for the font (calculated from package by default)
/// @param {string} $fontIncludePath Overwrite the full path to the font you want to include without the file extension (disables params $packageKey and $prefix!)
/// @outputs an entire @font-face declaration for ttf, woff and woff2 tailored to Neos websites
///
/// @example
///   @include font-face("Roboto", "Regular");
///   @include font-face("Roboto", "Bold");
///   @include font-face("Roboto", "Italic");
///   @include font-face("Roboto", "BoldItalic");
///
/// @example overwrite include path
///   @include font-face("MyFont", "Regular", $fontIncludePath: "/path/to/my/font");
@mixin font-face(
  $name,
  $style: "Regular",
  $fontDisplay: fallback,
  $packageKey: "Obis.Site",
  $prefix: "/_Resources/Static/Packages/#{$packageKey}/Fonts",
  $fontIncludePath: "#{$prefix}/#{$name}/#{$name}-#{$style}"
) {
  @font-face {
    font-family: "#{$name}";
    src: url("#{$fontIncludePath}.woff2") format("woff2"),
      url("#{$fontIncludePath}.woff") format("woff"),
      url("#{$fontIncludePath}.ttf") format("truetype");

    font-weight: fontStyleNameToWeight($style);
    font-style: fontStyleNameToStyle($style);
    font-display: $fontDisplay;
  }
}
