#vendue-teaser-container {
  display: none;
  position: fixed;
  right: -50.0px;
  top: calc(50% - 130px);
  z-index: 11;
  transform: scale(1) translate(0px);
  transition: transform 3s cubic-bezier(0.075, 0.82, 0.165, 1);

  .vendue-teaser-inner {
    &>img {
      max-width: 150px;
      height: auto;
      cursor: pointer;
      filter: drop-shadow(-3px 5px 6px rgba(0, 0, 0, 0.6));
    }
  }

  &:hover {
    transform: scale(1.1) translateX(-5px);
  }
}

.neos-backend #vendue-teaser-container {
  display: none !important;
}