/***
*
*   BREAKPOINT
*
*   ***
*
*   SMALL DEVICES
*
***/

.swiper-container .content h1,
.swiper-container .content h2,
.swiper-container .content h3 {
    font-weight: 600;
}

.swiper-container .content h3 {
    font-size: 25px;
}

@media (max-width: 767px) {
    .swiper-container .content {
        top: 50%;
    }
    .swiper-container-horizontal>.swiper-pagination {
        z-index: -1;
    }
}

@media (max-width: 550px) {
    .swiper-container .content {
        top: unset;
        bottom: 5px;
    }
    .swiper-container .content h3 {
        font-size: 14px;
    }
    .swiper-container .content h2 {
        font-size: 17px;
    }
    .swiper-container .content h1 {
        font-size: 19px;
    }

}

@media (max-width: 330px) {
    .swiper-container .content {
        top: 0%;
    }
}

@media (min-width: 768px) {
    .navbar-brand {
        max-width: 100%;
    }
    .navbar-nav>li>a {
        padding: 15px 5px;
    }
    .navbar-default .navbar-nav>li.active>a,
    .navbar-default .navbar-nav>li.current>a,
    .navbar-default .navbar-nav>li.active>a:hover,
    .navbar-default .navbar-nav>li.current>a:hover,
    .navbar-default .navbar-nav>li>a:hover {
        background-color: transparent;
    }
    .navbar-nav>li.active:after,
    .navbar-nav>li.current:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        bottom: 0;
        margin: 0 auto;
        right: 0;
        left: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid #143c6d;
    }
    .swiper-container .content {
        position: absolute;
        right: 0;
        left: 0;
        top: auto;
        bottom: 100px;
        width: 750px;
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
    }
    .swiper-container .content h1 {
        font-size: 36px;
    }
    .swiper-container .content h2 {
        font-size: 36px;
    }
    .swiper-container .content p {
        display: block;
    }
    #page .swiper-container .swiper-slide {
        max-height: 550px;
    }
    #homepage .swiper-container .swiper-slide {
        max-height: 700px;
    }
    #homepage .swiper-container .swiper-slide img {
        width: 100%;
        height: 85vh;
        object-fit: cover;
    }
    #content>div.container,
    #categories>div.container,
    #product>div.container {
        margin-top: 50px;
    }
    #categories .category-list-entry:nth-of-type(odd) {
        padding-right: 0;
    }
    #categories .category-list-entry:nth-of-type(even) {
        padding-left: 0;
    }
    #request-form .inputs-list li label {
        margin-top: 25px;
    }
    .social-media-icons .obisconcept-bootstrap-button {
        margin: 0 25px;
    }
    .link-switcher i {
        margin: 0 20px;
    }
    .nav-pills>li>a {
        padding: 10px 15px;
    }

	.obisconcept-bootstrap-table {
		width: 75%;
	}

}


/***
*
*   BREAKPOINT
*
*   ***
*
*   MEDIUM DEVICES
*
***/

@media (min-width: 992px) {
    .navbar-nav {
        position: relative;
        bottom: -75px;
    }
    .navbar-nav>li>a {
        padding: 15px 15px;
    }
    .swiper-container .content {
        width: 970px;
    }
    #homepage .swiper-container .swiper-slide {
        max-height: 840px;
    }
    #page .swiper-container .swiper-slide {
        max-height: 550px;
    }
    #homepage .swiper-container .swiper-slide img {
        width: 100%;
        height: 85vh;
        object-fit: cover;
    }
}


/***
*
*   BREAKPOINT
*
*   ***
*
*   LARGE DEVICES
*
***/

@media (min-width: 1200px) {
    .swiper-container .content {
        width: 1170px;
    }
}
