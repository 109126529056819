/***
*
*   FUNDAMENTALS
*
***/

@include font-face("TitilliumWeb", "ExtraLight", $prefix: "/_Resources/Static/Packages/Afi.MainWebsite/fonts");
@include font-face("TitilliumWeb", "ExtraLightItalic", $prefix: "/_Resources/Static/Packages/Afi.MainWebsite/fonts");
@include font-face("TitilliumWeb", "Light", $prefix: "/_Resources/Static/Packages/Afi.MainWebsite/fonts");
@include font-face("TitilliumWeb", "LightItalic", $prefix: "/_Resources/Static/Packages/Afi.MainWebsite/fonts");
@include font-face("TitilliumWeb", "Regular", $prefix: "/_Resources/Static/Packages/Afi.MainWebsite/fonts");
@include font-face("TitilliumWeb", "Italic", $prefix: "/_Resources/Static/Packages/Afi.MainWebsite/fonts");
@include font-face("TitilliumWeb", "SemiBold", $prefix: "/_Resources/Static/Packages/Afi.MainWebsite/fonts");
@include font-face("TitilliumWeb", "SemiBoldItalic", $prefix: "/_Resources/Static/Packages/Afi.MainWebsite/fonts");
@include font-face("TitilliumWeb", "Bold", $prefix: "/_Resources/Static/Packages/Afi.MainWebsite/fonts");
@include font-face("TitilliumWeb", "BoldItalic", $prefix: "/_Resources/Static/Packages/Afi.MainWebsite/fonts");
@include font-face("TitilliumWeb", "Black", $prefix: "/_Resources/Static/Packages/Afi.MainWebsite/fonts");

html, body {
    //overflow-x: hidden;
}

body {
    margin-bottom: 15px;
    font-family: 'TitilliumWeb', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #666;

    div.pkcp {
      > .pkcp-details {
        > .inner {
          > .header {
            > button {
              &.navbar-toggle {
                float: none;
              }
            }
            > .title {
              @media (max-width: 399px) {
                font-size: 14px;
                line-height: 2.2;
              }
            }
          }
        }
      }
    }
}

b, strong {
    font-weight: 600;
}

a {
    color: #666;
    text-decoration: underline;
}

a:hover {
    color: #666;
    text-decoration: none;
}

h1, h2, h3, h4 {
    color: #143c6d;
    text-transform: uppercase;
}

h3 {
    font-size: 18px;
}

hr {
    border-color: #666;
}



/***
*
*   HEADER
*
***/
.navbar {
    min-height: 115px;
    margin-bottom: 0;
    background-color: transparent;
    border: none;
}

.navbar-brand {
    max-width: calc(100% - 80px);
    height: 100px;
    margin-top: 10px;
}

.navbar-brand>img {
    width: auto;
    max-height: 100%;
}

.navbar-toggle {
    margin-top: 40px;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: #666;
}

.navbar-default .navbar-nav>li>a {
    color: #666;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
}


/***
*
*   SWIPER
*
***/

#swiper > .hidden-xs {
    display: block !important;
}

.swiper-container .head {
    background-color: transparent;
}

.swiper-container .head .head-inner, .swiper-container .content .content-inner {
    padding: 0;
}

.swiper-container .content {
    position: absolute;
}

.swiper-container .content h1,
.swiper-container .content h2,
.swiper-container .content h3 {
    display: inline-block;
    margin: 2px 0;
    padding: 10px;
    color: #fff;
    background-color: rgba(102, 102, 102, 0.8);
}

.swiper-container .content h1 {
    font-size: 20px;
}

.swiper-container .content h2,
.swiper-container .content h3 {
    padding: 5px 10px 3px;
}

.swiper-container .content h2 {
  font-size: 20px;
  padding: 10px;
}


.swiper-container .content p {
    display: none;
}

.swiper-container-horizontal > .swiper-pagination {
    top: auto;
    bottom: 10px;
    text-align: center;
}

.swiper-pagination-bullet.swiper-pagination-bullet {
    background: #666;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #fff;
}


/***
*
*   CONTENT
*
***/

#content>div.container,
#categories>div.container,
#product>div.container {
    padding-top: 30px;
    padding-bottom: 30px;;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
}

#categories .category-list-entry {
	padding-left: 0;
    margin-top: 15px;
}

#categories .category-list-entry img {
    width: 480px;
    height: 324px;
	object-fit: cover;
}

#categories.sold .category-list-entry:not(:hover) img {
    -webkit-filter: grayscale(1);
    -webkit-filter: grayscale(100%);
    filter: gray;
    filter: grayscale(100%);
    filter: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" height="0"><filter id="greyscale"><feColorMatrix type="matrix" values="0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0" /></filter></svg>#greyscale');
}

#categories .category-list-title a {
    color:  #143c6d;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
}

#categories .category-list-price {
    font-size: 18px;
    text-transform: uppercase;
}

#product .product-title {
    font-size: 18px;
}

#product div[id*='swiper-'] {
    display: block !important;
}


/***
*
*   NODE TYPES
*
***/

.teaser-box {
    margin: 15px -15px;
    border: none;
}

.teaser-box a {
    text-decoration: none;
}

.teaser-box img {
    width: 100%;
    margin-bottom: 15px;
}

.social-media-icons {
    border-top: 1px solid #666;
}

.social-media-icons>div {
    padding: 15px 0;
    border-bottom: 1px solid #666;
    text-align: center;
}

.social-media-icons .obisconcept-bootstrap-button {
    margin: 0 10px;
    float: none !important;
}

.social-media-icons .obisconcept-bootstrap-button a {
    width: 22px;
    height: 22px;
    padding: 2px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    background-color: #666;
    border: none;
}

.social-media-icons .obisconcept-bootstrap-button a i {
    margin-right: 0;
    float: none !important;
}

.link-switcher>div {
    padding: 15px 0;
    border-bottom: 1px solid #666;
    font-size: 15px;
}

.link-switcher p {
    margin: 0;
}

.link-switcher a {
    text-decoration: none;
}

.link-switcher a:hover {
    text-decoration: underline;
}

.link-switcher i {
    margin: 0 5px;
}

.assetlist a {
    text-decoration: none;
}

.assetlist span {
    display: block;
    font-size: 15px;
}

.assetlist i {
    display: block;
    margin: 10px 0;
    font-size: 70px;
}

.assetlist i.fa-download {
    font-size: 30px;
}


/***
*
*   CONTACT & REQUEST FORM
*
***/

#request-form div.row>fieldset>fieldset:nth-of-type(odd), #sell-form div.row>fieldset>fieldset:nth-of-type(odd) {
    clear: both;
}

#contact-form .form-group,
#sell-form .form-group,
#request-form .form-group,
#tap-form .form-group {
    margin-bottom: 4px;
}

#contact-form ul,
#sell-form ul,
#request-form ul,
#tap-form ul {
    padding: 20px 0 0;
    list-style: none;
}

#contact-form ul li,
#sell-form ul li,
#request-form ul li,
#tap-form ul li {
    float: left;
}

#contact-form ul li label input,
#sell-form ul li label input,
#request-form ul li label input,
#tap-form ul li label input {
    width: auto;
    height: auto;
    margin: 2px 10px;
    float: left;
}

#contact-form label,
#sell-form label,
#request-form label,
#tap-form label {
    padding-top: 0;
    text-align: left;
}

#contact-form input,
#sell-form input,
#request-form input,
#tap-form input {
    background-color: #f3f3f3;
    border-radius: 2px;
}

#contact-form fieldset:last-child label,
#sell-form fieldset:last-child label,
#request-form fieldset:last-child label,
#tap-form fieldset:last-child label {
    margin-top: 2px;
}

#contact-form fieldset:last-child .inputs-list label,
#sell-form fieldset:last-child .inputs-list label,
#request-form fieldset:last-child .inputs-list label,
#tap-form fieldset:last-child .inputs-list label {
    margin-top: 5px;
}

#contact-form button,
#sell-form button,
#request-form button,
#tap-form button {
    //border-color: transparent;
    font-size: 24px;
	background-color: #f0f0f0;
    height: 34px;
}

#contact-form button:hover,
#sell-form button:hover,
#request-form button:hover,
#tap-form button:hover {
    background-color: transparent;
}


#contact-form #contact-form-section-eleven, #tap-form #tap-form-section-eleven {
    margin-top: 10px;
}

#contact-form .checkbox, #tap-form .checkbox {
    padding-top: 0px;
}

#contact-form #contact-form-section-wrap-one,
#contact-form #contact-form-section-wrap-two,
#tap-form #tap-form-section-wrap-one,
#tap-form #tap-form-section-wrap-two {
    padding: 0;
}

#contact-form #contact-form-section-thirteen,
#contact-form #contact-form-section-fifteen,
#tap-form #tap-form-section-seventeen,
#tap-form #tap-form-section-nineteen {
    min-height: 55px;
}

#contact-form #contact-form-section-twentyone,
#tap-form #tap-form-section-twentyone {
    padding-bottom: 8px;
}

#contact-form #contact-form-section-twentyone,
#contact-form #contact-form-section-twentytwo,
#tap-form #tap-form-section-twentyone,
#tap-form #tap-form-section-twentytwo {
    padding-top: 10px;
}

#contact-form #contact-form-section-twenty p,
#tap-form #tap-form-section-twenty p {
    margin: 20px 0 0;
}

#contact-form #contact-form-section-twentyfour p,
#tap-form #tap-form-section-twentyfour p {
    margin: 10px 0 0;
}

#contact-form #contact-form-section-twentyfour p,
#tap-form #tap-form-section-twentyfour p {
    text-align: center;
}

#contact-form .form-navigation ul,
#sell-form .form-navigation ul,
#request-form .form-navigation ul,
#tap-form .form-navigation ul {
    padding: 0;
}


#sell-form-section-twenty, #sell-form-section-twentyone, #sell-form-section-twentytwo, #sell-form-section-twentythree, #sell-form-section-twentyfour, #sell-form-section-twentyfive
{
	display: none;
}


#contact-form-section-twenty, #contact-form-section-twentyone, #contact-form-section-twentytwo, #contact-form-section-twentythree, #contact-form-section-twentyfour, #contact-form-section-twentyfive
{
	display: none;
}

#tap-form-section-twenty,
#tap-form-section-twentyone,
#tap-form-section-twentytwo,
#tap-form-section-twentythree,
#tap-form-section-twentyfour,
#tap-form-section-twentyfive
{
	display: none;
}


/***
*
*   NEWSLETTER FROM
*
***/

#subscribe_1_ {
    margin-top: 20px;
}

#subscribe_1_ div[class*='col-'] {
    margin-bottom: 5px;
}

#subscribe_1_ label {
    margin-bottom: 0;
}

#subscribe_1_ input {
    background-color: #f3f3f3;
    border-radius: 2px;
}

#subscribe_1_ div[class*='col-']:first-child>span {
    display: inline-block;
    position: relative;
    top: -5px;
}

#subscribe_1_ input[type='radio'] {
    width: auto;
    height: auto;
    margin: 2px 10px;
    float: left;
}

#subscribe_1_ input[type='radio']+span {
    float: left;
}

#subscribe_1_ button {
    float: right;
    border-color: transparent;
    font-size: 30px;
}

#subscribe_1_ button:hover {
    background-color: transparent;
}


/***
*
*   FOOTER
*
***/

.nav-pills>li>a {
    padding: 10px;
    text-decoration: none;
}

.nav-pills>li.current>a,
.nav-pills>li.active>a,
.nav-pills>li.current>a:hover,
.nav-pills>li.active>a:hover,
.nav-pills>li>a:hover {
    color: #666;
    background-color: transparent;
}

.nav-pills>li>a:hover {
    text-decoration: underline;
}

.obisconcept-bootstrap-table table>tbody>tr>td,
.obisconcept-bootstrap-table table>tbody>tr>th,
.obisconcept-bootstrap-table table>tfoot>tr>td,
.obisconcept-bootstrap-table table>tfoot>tr>th,
.obisconcept-bootstrap-table table>thead>tr>td,
.obisconcept-bootstrap-table table>thead>tr>th {
    width: 50%;
}

figure.typo3-neos-alignment-center,
figure.neos-alignment-center {
    text-align: center;
}

figure.typo3-neos-alignment-left,
figure.neos-alignment-left {
    text-align: left;
}

figure.typo3-neos-alignment-right,
figure.neos-alignment-right {
    text-align: right;
}



figure.typo3-neos-alignment-center img,
figure.neos-alignment-center img,
figure.typo3-neos-alignment-left img,
figure.neos-alignment-left img,
figure.typo3-neos-alignment-right img,
figure.neos-alignment-right img
{
    display: inline;
}

.neos-nodetypes-image>figure>img.img-responsive {
    margin-bottom: 8px;
    display: block;
    width: 100%;
    height: auto;
    margin-right: 5px;
}



/* TAP ANIMATION */

#tap-teaser-container-wrapper {
    display: none;
}

#tap-teaser-container {
    position: fixed;
    right: 0;
    /* bottom: -400px; */
    bottom: 0;

    width: 400px;
    height: 357px;
    z-index: 99999;
}

#tap-teaser-container-middleware {
    position: absolute;
    left: 50px;
    right: 50px;
    bottom: 150px;
}

#tap-teaser-container-inner {
    position: relative;
}

#tap-teaser-container-inner > * {
    position: absolute;
    top: 400px;
}

#tap-teaser-container-inner > .mock-text {
    width: 60px;
    height: 80px;
}

#tap-teaser-container-inner > .separator-circle {
    width: 30px;
    height: 30px;
    margin: 25px 0;
}

#tap-animation--flower {
    position: absolute;
    left: 0;
    top: 400px;
}

#tap-animation--letter-t {
    left: 17px;
}

#tap-animation--circle-1 {
    left: 77px;
}

#tap-animation--letter-a {
    left: 117px;
}

#tap-animation--circle-2 {
    left: 182px;
}

#tap-animation--letter-p {
    left: 222px;
}

#tap-animation--subtitle {
    position: absolute;
    left: 0;
    color: #0E3B6B;
    width: 100%;
    text-align: center;
    font-size: 40pt;
    margin-top: 70px;
}

/* TAP ANIMATION IN BACKEND */

body.neos-backend #tap-teaser-container {
    position: relative;
    z-index: 0 !important;
}

body.neos-backend #tap-teaser-container-inner > * {
    top: 0;
}

body.neos-backend #tap-animation--flower {
    top: 0;
}

@media (max-width: 756px) {
    #tap-teaser-container-wrapper {
        display: none !important;
    }
}

/* END TAP ANIMATION */
